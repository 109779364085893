import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

import { iEnvironment } from '../interfaces/environment.interface';
import { iSelectedPrices } from '../interfaces/selected-prices.interface';

interface iCheckoutSession {
  id: string;
  url: string;
}

const DASHBOARD_URL = `${window.location.origin}`;

@Injectable({
  providedIn: 'root'
})
export class CheckoutApi {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  private churchService = inject(ChurchService);

  private environment: iEnvironment;

  constructor(@Inject('environment') environment: iEnvironment) {
    this.environment = environment;
  }

  checkout() {
    const churchId = this.churchService.churchId;

    this.http
      .post<iCheckoutSession>(`${this.environment.apiUrl}/checkout`, {
        churchId: churchId,
        returnUrl: DASHBOARD_URL
      })
      .subscribe(session => {
        window.location.href = session.url;
      });
  }

  subscribe(churchId: string, selectedPrices: iSelectedPrices[]): Observable<iCheckoutSession> {
    const user = this.authService.currentUser;

    const payload = {
      churchId,
      name: user.full_name,
      email: user.email,
      phone: user.phone,
      selectedPrices,
      returnUrl: DASHBOARD_URL
    };

    return this.http.post<iCheckoutSession>(`${this.environment.apiUrl}/checkout/subscribe`, payload);
  }
}
